// Filename: frontend/src/components/TaskSelection.js
import { useTranslation } from "react-i18next";
import tasks from "../config/tasks.json"; // Update the path as needed

const TaskSelection = ({ selectedTasks, setSelectedTasks }) => {
  const { t } = useTranslation();

  const handleTaskChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedTasks((prev) => [...prev, value]);
    } else {
      setSelectedTasks((prev) => prev.filter((task) => task !== value));
    }
  };

  return (
    <div className="mb-4">
      <label className="block text-lg font-semibold mb-2">
        {t("select_tasks") + ":"}
      </label>
      {tasks.map((task) => (
        <label key={task.id} className="flex items-center mb-2 cursor-pointer">
          <input
            type="checkbox"
            value={task.name}
            checked={selectedTasks.includes(task.name)}
            onChange={handleTaskChange}
            className="mr-2"
          />
          <span className="text-gray-700">{t(task.name)}</span>
        </label>
      ))}
    </div>
  );
};

export default TaskSelection; // Make sure this is a default export
