// src/utils/dateUtils.js
import { format } from "date-fns";
import { enUS, es, fr, nl } from "date-fns/locale"; // Import the locales you need

const locales = {
  en: enUS,
  es: es,
  fr: fr,
  nl: nl,
  // Add more locales as needed
};

/**
 * Formats a date to 'yyyy-MM-dd' using the specified locale.
 * @param {Date|string} date - The date to format, can be a Date object or a date string.
 * @param {string} locale - The locale to use for formatting.
 * @returns {string} - The formatted date as 'yyyy-MM-dd'.
 */
export const formatDate = (date, locale = "en") => {
  return format(new Date(date), "yyyy-MM-dd", { locale: locales[locale] });
};
