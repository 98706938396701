// src/components/ScheduleAvailableUsers.js

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBeer,
  faUtensils,
  faGlassCheers,
  faUsers,
} from "@fortawesome/free-solid-svg-icons"; // Import your icons

// Function to map task names to icons
const getIcon = (iconName) => {
  switch (iconName) {
    case "faBeer":
      return <FontAwesomeIcon icon={faBeer} />;
    case "faUtensils":
      return <FontAwesomeIcon icon={faUtensils} />;
    case "faGlassCheers":
      return <FontAwesomeIcon icon={faGlassCheers} />;
    case "faUsers":
      return <FontAwesomeIcon icon={faUsers} />;
    default:
      return null; // Handle the case where no icon is found
  }
};

const ScheduleAvailableUsers = ({
  availableUsers = [], // Default to empty array if undefined
  getTaskColor,
  handleDragStart,
}) => {
  // console.log(availableUsers); // Debugging line to see available users
  console.log("af" + availableUsers);
  return (
    <div className="flex flex-wrap">
      {availableUsers.length > 0 ? (
        availableUsers.map((user) => (
          <button
            key={user.id}
            className={`m-2 cursor-pointer p-2 rounded ${
              getTaskColor(
                user.tasks && user.tasks.length ? user.tasks[0].name : ""
              ) || "bg-gray-200"
            }`}
            draggable
            onDragStart={(e) => handleDragStart(user.id)(e)} // Call handleDragStart from props
          >
            {user.tasks && user.tasks.length > 0 && getIcon(user.tasks[0].icon)}
            {user.username || `User ID: ${user.id}`}
          </button>
        ))
      ) : (
        <p>No users available</p>
      )}
    </div>
  );
};

export default ScheduleAvailableUsers;
