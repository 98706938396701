// src/components/QrScanner.js

import React, { useState, useEffect } from "react";
import { BarcodeScanner } from "@thewirv/react-barcode-scanner";

const QrScanner = ({ onScan }) => {
  const [data, setData] = useState("No result");

  useEffect(() => {
    if (data && data !== "No result") {
      onScan(data);
    }
  }, [data, onScan]);

  return (
    <div style={{ position: "relative", width: "400px", height: "300px" }}>
      <BarcodeScanner
        onSuccess={(text) => setData(text)}
        onError={(error) => {
          if (error) {
            console.error(error.message);
          }
        }}
        onLoad={() => console.log("Video feed has loaded!")}
        containerStyle={{
          width: "100%",
          height: "100%",
          borderRadius: "8px",
          overflow: "hidden",
        }}
        videoConstraints={{
          width: 400, // Set a fixed width
          height: 300, // Set a fixed height
        }}
      />
    </div>
  );
};

export default QrScanner;
