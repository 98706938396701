// src/components/MakeSchedule.js
import React, { useEffect, useState, useMemo } from "react";
import tasks from "../../config/tasks.json";
import { useTranslation } from "react-i18next";
import config from "../../config";
import DateButtons from "../../components/DateButtons";
import TaskButtons from "../../components/TaskButtons";
import AvailableUsers from "../../components/ScheduleAvailableUsers";
import TimeSlots from "../../components/TimeSlots";
import { formatDate } from "../../utils/dateUtils"; // Importing formatDate utility

const MakeSchedule = () => {
  const { t, i18n } = useTranslation(); // Access i18n for current language
  const [availableUsersToday, setAvailableUsersToday] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTask, setSelectedTask] = useState("");
  const [todaysSchedule, setTodaysSchedule] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state
  const tenantId = localStorage.getItem("tenantId");

  useEffect(() => {
    const fetchUsersData = async () => {
      if (!selectedDate) return;

      setLoading(true); // Start loading
      try {
        const [availableUsersTodayResponse, scheduledUsersResponse] =
          await Promise.all([
            fetch(
              `${config.API_URL}/admin/schedule/get_available_users_today?tenantId=${tenantId}&date=${selectedDate}`
            ),
            fetch(
              `${config.API_URL}/admin/schedule/get_already_scheduled_users?tenantId=${tenantId}&date=${selectedDate}`
            ),
          ]);

        if (!availableUsersTodayResponse.ok) {
          throw new Error(
            `Error fetching availability: ${availableUsersTodayResponse.statusText}`
          );
        }

        if (!scheduledUsersResponse.ok) {
          throw new Error(
            `Error fetching scheduled users: ${scheduledUsersResponse.statusText}`
          );
        }

        const availableUsersTodayData =
          await availableUsersTodayResponse.json();
        const alreadyScheduledUsersToday = await scheduledUsersResponse.json();

        if (!availableUsersTodayData.success) {
          setError(availableUsersTodayData.message);
          setAvailableUsersToday([]);
          return;
        }

        const formattedScheduledUsers = alreadyScheduledUsersToday.map(
          (user) => ({
            ...user,
            schedule_date: formatDate(user.schedule_date, i18n.language), // Format using locale
          })
        );

        const filteredAvailableUsers =
          availableUsersTodayData.availailableUsersToday.filter(
            (user) =>
              !formattedScheduledUsers.some(
                (scheduledUser) => scheduledUser.id === user.id
              )
          );

        setAvailableUsersToday(filteredAvailableUsers || []);
        setTodaysSchedule(formattedScheduledUsers || []);
      } catch (err) {
        console.error("Error fetching users data:", err);
        setError(t("error_fetching_users_data"));
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchUsersData();
  }, [selectedDate, t, i18n.language]); // Add i18n.language as a dependency

  const getTaskColor = (taskName) => {
    const task = tasks.find((task) => task.name === taskName);
    return task ? task.color : "bg-gray-200";
  };

  const handleTaskClick = (taskName) => {
    setSelectedTask(taskName);
    setError(null);
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
    setError(null);
    setSelectedTask("");
    setTodaysSchedule([]);
  };

  const handleRemoveFromTimeSlot = (scheduledUser) => {
    setTodaysSchedule((prev) =>
      prev.filter((user) => user.id !== scheduledUser.id)
    );
    setAvailableUsersToday((prev) => [
      ...prev,
      {
        id: scheduledUser.id,
        username: scheduledUser.username,
        task: scheduledUser.task,
      },
    ]);
  };

  const handleSaveSchedule = async () => {
    if (todaysSchedule.length === 0) {
      setError(t("no_users_scheduled"));
      return;
    }

    try {
      const response = await fetch(`${config.API_URL}/admin/save_schedule`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ schedule: todaysSchedule }),
      });
      const data = await response.json();
      if (!data.success) throw new Error(data.message);
      console.log(t("schedules_saved_successfully"));
    } catch (error) {
      console.error("Error saving schedule:", error);
      setError(t("error_saving_schedule"));
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragStart = (userId) => (e) => {
    console.log("Drag start from AvailableUsers or TimeSlots:", userId);
    e.dataTransfer.setData("userId", userId);
  };

  const handleDrop = (time) => (e) => {
    e.preventDefault();
    const userId = e.dataTransfer.getData("userId");

    const draggedUser = availableUsersToday.find(
      (user) => user.id === Number(userId)
    );

    if (!draggedUser) {
      const scheduledUser = todaysSchedule.find(
        (user) => user.id === Number(userId)
      );
      if (scheduledUser) {
        const isScheduledAtDifferentTime = todaysSchedule.some(
          (user) => user.id === scheduledUser.id && user.schedule_time !== time
        );
        if (isScheduledAtDifferentTime) {
          const updatedUser = {
            ...scheduledUser,
            schedule_date: selectedDate,
            schedule_time: time,
            task: scheduledUser.task,
          };
          setTodaysSchedule((prevUsers) =>
            prevUsers.map((user) =>
              user.id === updatedUser.id ? updatedUser : user
            )
          );
          console.log(`Moved user: ${scheduledUser.username} to ${time}`);
        } else {
          console.warn(
            `User ${scheduledUser.username} is already scheduled at this time.`
          );
        }
      } else {
        console.error("User not found with ID:", userId);
      }
    } else {
      const newUser = {
        ...draggedUser,
        schedule_date: selectedDate,
        schedule_time: time,
        task: selectedTask,
      };

      const isAlreadyScheduled = todaysSchedule.some(
        (user) => user.id === newUser.id && user.schedule_time === time
      );
      if (!isAlreadyScheduled) {
        setTodaysSchedule((prevUsers) => [...prevUsers, newUser]);
        setAvailableUsersToday((prev) =>
          prev.filter((user) => user.id !== draggedUser.id)
        );
        console.log(`Dropped user: ${draggedUser.username} at ${time}`);
      } else {
        console.warn(
          `User ${draggedUser.username} is already scheduled at this time.`
        );
      }
    }
  };

  const filteredAvailableUsers = useMemo(() => {
    return selectedTask
      ? availableUsersToday.filter(
          (user) => user.task && user.task.split(",").includes(selectedTask)
        )
      : availableUsersToday;
  }, [availableUsersToday, selectedTask]);

  return (
    <div className="flex flex-col p-4">
      <h1 className="text-lg font-bold">{t("make_schedule")}</h1>
      {loading && <div className="text-blue-500">{t("loading")}</div>}{" "}
      {/* Loading state */}
      {error && <div className="text-red-500">{error}</div>}
      <DateButtons
        selectedDate={selectedDate}
        handleDateClick={handleDateClick}
      />
      <TaskButtons
        tasks={tasks}
        selectedTask={selectedTask}
        handleTaskClick={handleTaskClick}
        getTaskColor={getTaskColor}
      />
      <h2 className="text-md font-semibold">{t("available_users")}</h2>
      {filteredAvailableUsers.length === 0 ? (
        <p>{t("no_users_available_for_task")}</p>
      ) : (
        <AvailableUsers
          availableUsers={filteredAvailableUsers}
          handleDragStart={handleDragStart}
          getTaskColor={getTaskColor}
        />
      )}
      <h2 className="text-md font-semibold">{t("time_slots")}</h2>
      <TimeSlots
        todaysSchedule={todaysSchedule}
        handleDragStart={handleDragStart}
        handleDrop={handleDrop}
        handleRemoveFromTimeSlot={handleRemoveFromTimeSlot}
        getTaskColor={getTaskColor}
        handleDragOver={handleDragOver}
      />
      <button
        onClick={handleSaveSchedule}
        className="mt-4 bg-blue-500 text-white p-2 rounded"
      >
        {t("save_schedule")}
      </button>
    </div>
  );
};

export default MakeSchedule;
