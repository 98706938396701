// Filename: frontend/src/components/SubmitWorkedHours.js
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import config from "../config";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const SubmitWorkedHours = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [startHour, setStartHour] = useState("16");
  const [startMinute, setStartMinute] = useState("00");
  const [endHour, setEndHour] = useState("17");
  const [endMinute, setEndMinute] = useState("00");
  const [error, setError] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [workedHours, setWorkedHours] = useState({
    startTime: "",
    endTime: "",
  });

  // Fetch start time from the database
  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      fetch(
        `${config.API_URL}/user/submit-hours/get-start-time?userId=${userId}`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.success && data.startTime) {
            const startTime = new Date(data.startTime);
            setStartHour(String(startTime.getHours()).padStart(2, "0"));
            setStartMinute(String(startTime.getMinutes()).padStart(2, "0"));
          } else {
            setError(t("error_fetching_start_time"));
          }
        })
        .catch((error) => {
          console.error("Error fetching start time:", error);
          setError(t("error_occurred"));
        });
    }
  }, [t]);

  // Round time to nearest 15 minutes
  const roundToNearest15Minutes = (date) => {
    const minutes = Math.round(date.getMinutes() / 15) * 15;
    date.setMinutes(minutes);
    date.setSeconds(0);
    return date;
  };

  useEffect(() => {
    const now = new Date();
    const roundedEndTime = roundToNearest15Minutes(new Date(now));
    setEndHour(String(roundedEndTime.getHours()).padStart(2, "0"));
    setEndMinute(String(roundedEndTime.getMinutes()).padStart(2, "0"));
  }, []);

  // Generate hour options (00-23)
  const generateHourOptions = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      const time = String(hour).padStart(2, "0");
      options.push(
        <option key={time} value={time}>
          {time}
        </option>
      );
    }
    return options;
  };

  // Generate minute options (00, 15, 30, 45)
  const generateMinuteOptions = () => {
    const options = [];
    for (let minute = 0; minute < 60; minute += 15) {
      const time = String(minute).padStart(2, "0");
      options.push(
        <option key={time} value={time}>
          {time}
        </option>
      );
    }
    return options;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const userId = localStorage.getItem("userId");
    if (!userId) {
      setError("User ID is not found.");
      return;
    }

    const startTime = `${startHour}:${startMinute}`;
    const endTime = `${endHour}:${endMinute}`;

    // Show confirmation
    setWorkedHours({ startTime, endTime });
    setShowConfirmation(true);
  };

  const confirmSubmission = () => {
    const userId = localStorage.getItem("userId");
    const { startTime, endTime } = workedHours;

    const startDateTime = `${startDate}T${startTime}:00`;
    const endDateTime = `${startDate}T${endTime}:00`;

    fetch(`${config.API_URL}/user/submit-worked-hours`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        userId,
        startTime: startDateTime,
        endTime: endDateTime,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setTimeout(() => {
            navigate("/user/view-worked-hours");
          }, 2000);
        } else {
          setError(
            t("submit_failure", { message: data.message || "Unknown error" })
          );
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setError(t("error_occurred"));
      });
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  // Handle changing date
  const handleDateChange = (direction) => {
    const currentDate = new Date(startDate);
    if (direction === "prev") {
      currentDate.setDate(currentDate.getDate() - 1);
    } else if (direction === "next") {
      const today = new Date();
      if (currentDate.toDateString() === today.toDateString()) return; // Prevent selecting tomorrow
      currentDate.setDate(currentDate.getDate() + 1);
    }
    setStartDate(currentDate.toISOString().split("T")[0]);
  };

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-bold mb-4 text-center text-charcoal">
        {t("submit_hours")}
      </h2>
      {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
      {!showConfirmation ? (
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex items-center space-x-2">
            <button
              type="button"
              onClick={() => handleDateChange("prev")}
              className="text-charcoal hover:text-persianGreen"
            >
              <FaArrowLeft />
            </button>
            <div className="flex-grow">
              <label
                htmlFor="startDate"
                className="block text-charcoal font-semibold mb-1"
              >
                {t("date")}
              </label>
              <input
                type="date"
                id="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-persianGreen"
                required
                max={new Date().toISOString().split("T")[0]}
              />
            </div>
            <button
              type="button"
              onClick={() => handleDateChange("next")}
              className="text-charcoal hover:text-persianGreen"
            >
              <FaArrowRight />
            </button>
          </div>
          <div>
            <label
              htmlFor="startTime"
              className="block text-charcoal font-semibold mb-1"
            >
              {t("start_time")}
            </label>
            <div className="flex space-x-4">
              <select
                id="startHour"
                value={startHour}
                onChange={(e) => setStartHour(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              >
                {generateHourOptions()}
              </select>
              <select
                id="startMinute"
                value={startMinute}
                onChange={(e) => setStartMinute(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              >
                {generateMinuteOptions()}
              </select>
            </div>
          </div>
          <div>
            <label
              htmlFor="endTime"
              className="block text-charcoal font-semibold mb-1"
            >
              {t("end_time")}
            </label>
            <div className="flex space-x-4">
              <select
                id="endHour"
                value={endHour}
                onChange={(e) => setEndHour(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              >
                {generateHourOptions()}
              </select>
              <select
                id="endMinute"
                value={endMinute}
                onChange={(e) => setEndMinute(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              >
                {generateMinuteOptions()}
              </select>
            </div>
          </div>
          <div className="flex justify-between">
            <button
              type="submit"
              className="w-full bg-persianGreen text-white py-2 rounded-md hover:bg-darkGreen"
            >
              {t("submit")}
            </button>
          </div>
        </form>
      ) : (
        <div className="space-y-4">
          <p className="text-center">{t("confirm_submission")}</p>
          <p>
            {t("start_time")}: {workedHours.startTime}
          </p>
          <p>
            {t("end_time")}: {workedHours.endTime}
          </p>
          <div className="flex justify-between">
            <button
              onClick={handleCancel}
              className="w-full bg-gray-300 py-2 rounded-md hover:bg-gray-400"
            >
              {t("cancel")}
            </button>
            <button
              onClick={confirmSubmission}
              className="w-full bg-persianGreen text-white py-2 rounded-md hover:bg-darkGreen"
            >
              {t("confirm")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubmitWorkedHours;
