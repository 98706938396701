// src/components/LogoutButton.js

import React from "react";
import { FaSignOutAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const LogoutButton = ({ handleLogout }) => {
  const { t } = useTranslation();

  return (
    <button
      onClick={handleLogout}
      className="text-white hover:bg-primary-light px-3 py-2 rounded-md text-sm font-medium"
    >
      <FaSignOutAlt size={18} className="mr-2" />
    </button>
  );
};

export default LogoutButton;
