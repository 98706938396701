import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ShareQrCode from "../components/ShareQrCode"; // Adjust the path as necessary

const Home = () => {
  const qrValue = String(localStorage.getItem("tenantUUID"));
  const { t } = useTranslation("homepage");
  const isLoggedIn = localStorage.getItem("userId"); // Check if the user is logged in
  const [showQrCode, setShowQrCode] = useState(false); // State to manage QR code visibility

  const handleShowQrCode = () => {
    setShowQrCode(true); // Show QR code when button is clicked
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen text-black p-6">
      {/* Welcome Section */}
      <div className="text-center mb-6">
        <h1 className="text-5xl mb-4">{t("welcomeMessage")}</h1>
      </div>
      {/* Call to Action Section */}
      <div className="container mx-auto px-4 py-8">
        {!isLoggedIn && (
          <div className="mt-8 flex flex-col items-center">
            <Link
              to="/user/login"
              className="bg-persianGreen text-white font-bold py-2 px-4 rounded-lg shadow hover:bg-saffron transition duration-200 w-32 text-center"
            >
              {t("Login")}
            </Link>
          </div>
        )}
        {isLoggedIn && (
          <div className="mt-8 flex flex-col items-center">
            <button
              onClick={handleShowQrCode}
              className="bg-persianGreen text-white font-bold py-2 px-4 rounded-lg shadow hover:bg-saffron transition duration-200 w-32 text-center"
            >
              {t("share_qrcode")}
            </button>
          </div>
        )}
      </div>

      {/* Show the QR Code component if showQrCode is true */}
      {showQrCode && <ShareQrCode value={qrValue} />}

      {/* Beta Message */}
      <div className="mt-8 bg-sandyBrown p-6 rounded-lg text-white shadow-lg max-w-lg text-center">
        <p className="text-lg">{t("trackWorkHoursDescription")}</p>
      </div>
    </div>
  );
};

export default Home;
