import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Import Link for navigation
import config from "../config"; // Import config
import { useTranslation } from "react-i18next";

const UserViewSchedule = () => {
  const { t } = useTranslation();
  const [schedules, setSchedules] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    // Fetch user's schedule from the backend
    const fetchUserSchedule = async () => {
      const userId = localStorage.getItem("userId"); // Retrieve userId from localStorage or other source

      if (!userId) {
        setError("User ID not found.");
        return;
      }

      try {
        const response = await fetch(
          `${config.API_URL}/user/schedule/view/${userId}`
        );
        const data = await response.json();
        if (data.success) {
          setSchedules(data.schedules);
        } else {
          setError(data.message || "Failed to load schedule.");
        }
      } catch (err) {
        setError("An error occurred while fetching the schedule.");
      }
    };

    fetchUserSchedule();
  }, []);

  const formatDate = (dateString) => {
    const userLocale = navigator.language || "en-US"; // Get user's locale
    const date = new Date(dateString); // Convert dateString to Date object

    return date.toLocaleDateString(userLocale, {
      month: "short", // Show short month name (e.g., Jan)
      day: "numeric", // Show day of the month (e.g., 1)
    });
  };

  // Function to format schedule time to HH:MM
  const formatTime = (timeString) => {
    const date = new Date(`1970-01-01T${timeString}Z`); // Create date object with a fixed date
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false, // Use 24-hour format
    });
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-xl font-bold mb-4">Your Schedule</h2>

      {error && <p className="text-red-500">{error}</p>}

      {schedules.length > 0 ? (
        <table className="table-auto w-full border-collapse border border-gray-300">
          <thead>
            <tr>
              <th className="border px-4 py-2">Date</th>
              <th className="border px-4 py-2">Time</th>
              <th className="border px-4 py-2">Task</th>
            </tr>
          </thead>
          <tbody>
            {schedules.map((schedule) => (
              <tr key={`${schedule.schedule_date}-${schedule.schedule_time}`}>
                <td className="border px-4 py-2">
                  <Link
                    to={`/user/schedule/view/${
                      schedule.schedule_date.split("T")[0]
                    }`} // Format as YYYY-MM-DD
                    className="text-blue-500 hover:underline"
                  >
                    {formatDate(schedule.schedule_date)}
                  </Link>
                </td>
                <td className="border px-4 py-2">
                  {formatTime(schedule.schedule_time)}{" "}
                  {/* Updated to use formatTime */}
                </td>
                <td className="border px-4 py-2">{t(schedule.task)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        !error && <p>No schedules found.</p>
      )}
    </div>
  );
};

export default UserViewSchedule;
