// src/pages/AdminDashboard.js
import React, { useState, useEffect } from "react";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import EmployeeList from "./AdminEmployeeList";
import UserViewWorkedHours from "../UserViewWorkedHours";

const AdminDashboard = () => {
  const navigate = useNavigate();

  // Ensure user is admin
  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role !== "admin") {
      //  navigate("/login");
    }
  }, [navigate]);

  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold mb-6">Admin Dashboard</h1>
      <nav className="mb-6">
        <Link
          to="/admin/employees"
          className="text-blue-500 hover:underline mr-4"
        >
          Employee List
        </Link>
        <Link
          to="/tenant/register"
          className="text-blue-500 hover:underline mr-4"
        >
          tenant register
        </Link>
      </nav>
      <Routes>
        <Route path="employees" element={<EmployeeList />} />
        <Route path="employees/:userId" element={<UserViewWorkedHours />} />
      </Routes>
    </div>
  );
};

export default AdminDashboard;
