// Filename: src/layouts/Layout.js

import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import UserTopNavbar from "../components/UserTopNavbar";
import UserBottomNavbar from "../components/UserBottomNavbar";
import AdminTopNavbar from "../components/AdminTopNavbar";
import AdminBottomNavbar from "../components/AdminBottomNavbar";
import { TenantContext } from "../contexts/TenantProvider";

const Layout = ({ children, isLoggedIn, handleLogout }) => {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith("/admin");

  // Access tenant information from the TenantContext
  const tenant = useContext(TenantContext);

  const tenantName = tenant?.tenantName || "Tenant Name";

  return (
    <div className="flex flex-col min-h-screen pb-20">
      <div className="bg-charcoal text-white text-center py-4">
        <h2 className="text-xl">{tenantName}</h2>
      </div>
      <div className="hidden sm:block">
        {isAdminRoute ? (
          <AdminTopNavbar isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
        ) : (
          <UserTopNavbar isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
        )}
      </div>
      <div className="block sm:hidden">
        {!isAdminRoute && (
          <UserBottomNavbar
            isLoggedIn={isLoggedIn}
            handleLogout={handleLogout}
          />
        )}
        {isAdminRoute && (
          <AdminBottomNavbar
            isLoggedIn={isLoggedIn}
            handleLogout={handleLogout}
          />
        )}
      </div>
      <main className="flex-grow">{children}</main>
    </div>
  );
};

export default Layout;
