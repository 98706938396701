// Filename: src/pages/admin/AdminCheckedInUsers.js

import React, { useEffect, useState } from "react";
import config from "../../config";
import { useTranslation } from "react-i18next";
import {
  FaCheckCircle,
  FaTimesCircle,
  FaChevronUp,
  FaChevronDown,
} from "react-icons/fa";

const AdminCheckedInUsers = () => {
  const { t } = useTranslation();
  const [checkIns, setCheckIns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentDate] = useState(new Date().toISOString().split("T")[0]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  useEffect(() => {
    const fetchCheckIns = async () => {
      try {
        const response = await fetch(`${config.API_URL}/admin/checkins`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ date: currentDate }),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setCheckIns(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCheckIns();
  }, [currentDate]);

  // Calculate totals for tasks
  const taskTotals = checkIns.reduce((totals, checkIn) => {
    totals[checkIn.task] = (totals[checkIn.task] || 0) + 1;
    return totals;
  }, {});

  // Sorting function
  const sortedCheckIns = [...checkIns].sort((a, b) => {
    if (sortConfig.key) {
      const direction = sortConfig.direction === "asc" ? 1 : -1;
      return a[sortConfig.key] > b[sortConfig.key]
        ? direction
        : a[sortConfig.key] < b[sortConfig.key]
        ? -direction
        : 0;
    }
    return 0;
  });

  // Handle sorting by column
  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  // Helper to render sort icon
  const renderSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? (
        <FaChevronUp />
      ) : (
        <FaChevronDown />
      );
    }
    return null;
  };

  if (loading) return <p>{t("_loading")}...</p>;
  if (error)
    return (
      <p>
        {t("_error")}: {error}
      </p>
    );

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">{t("todays_schedule")}</h1>
      <h2 className="text-lg mb-2">
        {t("date")}: {currentDate}
      </h2>

      {/* Display task totals */}
      <div className="mb-4">
        {Object.entries(taskTotals).map(([task, count]) => (
          <div key={task} className="text-gray-700">
            {t(task)}: {count}
          </div>
        ))}
      </div>

      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr className="bg-gray-200">
            <th
              className="border px-4 py-2 cursor-pointer"
              onClick={() => handleSort("username")}
            >
              {t("name")}{" "}
              <span className="inline-block">{renderSortIcon("username")}</span>
            </th>
            <th
              className="border px-4 py-2 cursor-pointer"
              onClick={() => handleSort("schedule_time")}
            >
              {t("start")}{" "}
              <span className="inline-block">
                {renderSortIcon("schedule_time")}
              </span>
            </th>
            <th
              className="border px-4 py-2 cursor-pointer"
              onClick={() => handleSort("task")}
            >
              {t("task")}{" "}
              <span className="inline-block">{renderSortIcon("task")}</span>
            </th>
            <th
              className="border px-4 py-2 cursor-pointer"
              onClick={() => handleSort("check_in_status")}
            >
              {t("checked_in")}{" "}
              <span className="inline-block">
                {renderSortIcon("check_in_status")}
              </span>
            </th>
          </tr>
        </thead>

        <tbody>
          {sortedCheckIns.length > 0 ? (
            sortedCheckIns.map((checkIn, index) => (
              <tr key={index}>
                <td className="border px-4 py-2">{checkIn.username}</td>
                <td className="border px-4 py-2">
                  {new Date(
                    `1970-01-01T${checkIn.schedule_time}`
                  ).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </td>
                <td className="border px-4 py-2">{t(checkIn.task)}</td>
                <td className="border px-4 py-2 text-center">
                  {checkIn.check_in_status === "Completed" ? (
                    <>
                      <FaCheckCircle className="text-green-500 inline-block" />
                      <FaCheckCircle className="text-green-500 inline-block ml-1" />
                    </>
                  ) : checkIn.check_in_status === "Checked In" ? (
                    <FaCheckCircle className="text-green-500 inline-block" />
                  ) : (
                    <FaTimesCircle className="text-red-500 inline-block" />
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="border px-4 py-2 text-center">
                {t("_no_check_ins_found")}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AdminCheckedInUsers;
