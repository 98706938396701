// src/components/ShareQrCode.js
import React from "react";
import { QRCodeCanvas } from "qrcode.react"; // or QRCodeSVG

const ShareQrCode = ({ value }) => {
  return (
    <div>
      <QRCodeCanvas value={value} size={256} />
    </div>
  );
};

export default ShareQrCode;
