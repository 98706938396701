// Filename: src/App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "./i18n";

import Layout from "./layouts/Layout";
import Home from "./pages/HomePage";
import LoginInstructions from "./pages/LoginInstructions";
import PageNotFound from "./pages/PageNotFound";

import TenantRegister from "./pages/tenant/TenantRegister";

import Login from "./pages/Login";
import UserRegister from "./pages/UserRegister";

import SubmitWorkedHours from "./pages/SubmitWorkedHours";
import UserCheckIn from "./pages/UserCheckIn";
import UserViewWorkedHours from "./pages/UserViewWorkedHours";
import UserSetAvailability from "./pages/UserSetAvailability";
import ShowSchedule from "./pages/ShowSchedule";
import UserViewSchedule from "./pages/UserViewSchedule";

import AdminDashboard from "./pages/admin/AdminDashboard";
import AdminEmployeeList from "./pages/admin/AdminEmployeeList";
import MakeSchedule from "./pages/admin/MakeSchedule";
import AdminConfirmHours from "./pages/admin/AdminConfirmHours";
import AdminCheckedInUsers from "./pages/admin/AdminCheckedInUsers";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("userId");
  };

  return (
    <DndProvider backend={HTML5Backend} options={{ enableMouseEvents: true }}>
      <Router>
        <Layout isLoggedIn={isLoggedIn} handleLogout={handleLogout}>
          <Routes>
            {/* User Routes */}
            <Route path="/" element={<Home />} />
            <Route path="/logininstructions" element={<LoginInstructions />} />
            <Route path="/user/view/schedule" element={<UserViewSchedule />} />
            <Route path="/user/check-in" element={<UserCheckIn />} />
            <Route
              path="/user/submit-worked-hours"
              element={<SubmitWorkedHours />}
            />
            <Route
              path="/user/view-worked-hours"
              element={<UserViewWorkedHours />}
            />
            <Route
              path="/user/schedule/view/:date"
              element={<ShowSchedule />}
            />
            <Route
              path="/user/set-availability"
              element={<UserSetAvailability />}
            />
            <Route
              path="/user/login/:tenantUUID?"
              element={<Login onLogin={handleLogin} />}
            />
            <Route
              path="/user/register/:tenantUUID?"
              element={<UserRegister onLogin={handleLogin} />}
            />
            <Route
              path="/tenant/register"
              element={<TenantRegister onLogin={handleLogin} />}
            />
            {/* Admin Routes */}
            <Route path="/admin" element={<AdminDashboard />} />
            <Route path="/admin/make-schedule" element={<MakeSchedule />} />
            <Route
              path="/admin/confirm-user-hours"
              element={<AdminConfirmHours />}
            />
            <Route path="/admin/employees" element={<AdminEmployeeList />} />
            <Route
              path="/admin/checkedin-users"
              element={<AdminCheckedInUsers />}
            />
            {/* Catch-all Route for 404 */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Layout>
      </Router>
    </DndProvider>
  );
}

export default App;
