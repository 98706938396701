// src/pages/ShowSchedule.js
import React, { useEffect, useState } from "react";
import tasks from "../config/tasks.json"; // Import tasks from JSON
import { useParams } from "react-router-dom";
import DateButtons from "../components/DateButtons";
import TimeSlots from "../components/TimeSlots"; // Import TimeSlots component
import config from "../config";
import { format } from "date-fns";

const ShowSchedule = () => {
  const { date } = useParams(); // Extract date from URL parameters
  const today = new Date();
  const defaultDate = date || today.toISOString().split("T")[0];
  const [scheduledUsers, setScheduledUsers] = useState([]);
  const [selectedDate, setSelectedDate] = useState(defaultDate); // Initialize selectedDate

  useEffect(() => {
    if (selectedDate) {
      // Fetch scheduled users only if date is defined
      fetch(
        `${config.API_URL}/admin/schedule/get_already_scheduled_users/${selectedDate}`
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          const formattedScheduledUsers = data.map((user) => ({
            ...user,
            schedule_date: format(new Date(user.schedule_date), "yyyy-MM-dd"),
          }));
          setScheduledUsers(formattedScheduledUsers);
        })
        .catch((error) =>
          console.error("Error fetching scheduled users:", error)
        );
    }
  }, [selectedDate]);

  const handleDateClick = (newDate) => {
    setSelectedDate(newDate);
    // Update the URL with the new date
    window.history.pushState({}, "", `/user/schedule/view/${newDate}`);
  };

  // Function to get task color based on task name
  const getTaskColor = (task) => {
    const taskItem = tasks.find((t) => t.name === task);
    return taskItem ? taskItem.color : "bg-gray-300"; // Default color if task not found
  };

  // Function to get task icon based on task name
  const getTaskIcon = (task) => {
    const taskItem = tasks.find((t) => t.name === task);
    return taskItem ? taskItem.icon : null; // Return icon name if found
  };

  return (
    <div>
      <h1>Scheduled Users for {selectedDate}</h1>
      <DateButtons
        selectedDate={selectedDate}
        handleDateClick={handleDateClick}
      />
      {/* Render TimeSlots component */}
      <TimeSlots
        todaysSchedule={scheduledUsers} // Ensure correct prop name
        getTaskColor={getTaskColor}
        getTaskIcon={getTaskIcon} // Pass getTaskIcon function to TimeSlots
      />
    </div>
  );
};

export default ShowSchedule;
