// src/pages/UserRegister.js

import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TaskSelection from "../components/TaskSelection";
import QrScanner from "../components/QrScanner"; // Import the QR Scanner component
import config from "../config";

const UserRegister = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tenantUUID } = useParams();

  const [tenantName, setTenantName] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [isIdValid, setIsIdValid] = useState(false);
  const [manualTenantUUID, setManualTenantUUID] = useState(tenantUUID || "");
  const [formData, setFormData] = useState({
    username: "",
    employee_nr: "",
    password: "",
    confirmPassword: "",
    role: "employee",
  });
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [errorMessages, setErrorMessages] = useState({
    username: "",
    password: "",
    employee_nr: "",
    confirmPassword: "",
    tasks: "",
    general: "",
    tenantUUID: "",
  });
  const [showScannerModal, setShowScannerModal] = useState(false); // Modal visibility

  const validateTenantUUID = useCallback(() => {
    setErrorMessages((prevState) => ({ ...prevState, tenantUUID: "" }));
    fetch(`${config.API_URL}/tenant/validate`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ tenantUUID: manualTenantUUID }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.isValid) {
          setIsIdValid(true);
          setTenantName(data.tenantName);
          setTenantId(data.tenantId);
          setShowScannerModal(false); // Hide scanner on success
        } else {
          setIsIdValid(false);
          setErrorMessages((prevState) => ({
            ...prevState,
            tenantUUID: t("login.tenantIdRequired"),
          }));
        }
      })
      .catch((error) => {
        console.error("Tenant UUID validation error:", error);
        setErrorMessages((prevState) => ({
          ...prevState,
          tenantUUID: t("tenantUUIDValidationError"),
        }));
      });
  }, [manualTenantUUID, t]);

  useEffect(() => {
    if (tenantUUID) {
      validateTenantUUID();
    }
  }, [validateTenantUUID, tenantUUID]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleTenantUUIDChange = (e) => {
    setManualTenantUUID(e.target.value);
  };

  const handleScanUUID = (scannedUUID) => {
    setManualTenantUUID(scannedUUID);
    validateTenantUUID(scannedUUID); // Validate on successful scan
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrorMessages({
      username: "",
      password: "",
      confirmPassword: "",
      general: "",
      tasks: "",
    });

    if (formData.password.trim() !== formData.confirmPassword.trim()) {
      setErrorMessages((prevState) => ({
        ...prevState,
        confirmPassword: t("passwordMismatch"),
      }));
      return;
    }

    if (selectedTasks.length === 0) {
      setErrorMessages((prevState) => ({
        ...prevState,
        tasks: t("atLeastOneTaskRequired"),
      }));
      return;
    }

    const dataToSubmit = {
      ...formData,
      tasks: selectedTasks,
      tenantId,
    };

    fetch(`${config.API_URL}/user/register`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataToSubmit),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          localStorage.setItem("userId", data.userId);
          localStorage.setItem("userName", data.userName);
          localStorage.setItem("userRole", data.role);
          localStorage.setItem("tenantUUID", tenantUUID);

          navigate("/logininstructions");
        } else {
          if (data.message === "Username already exists") {
            setErrorMessages((prevState) => ({
              ...prevState,
              username: t("usernameTaken"),
            }));
          } else if (data.message === "Employee number already exists") {
            setErrorMessages((prevState) => ({
              ...prevState,
              employee_nr: t("employeeNrTaken"),
            }));
          } else {
            setErrorMessages((prevState) => ({
              ...prevState,
              general: t("registrationFailed"),
            }));
          }
        }
      })
      .catch((error) => {
        console.error("Registration error:", error);
        setErrorMessages((prevState) => ({
          ...prevState,
          general: t("errorOccurred"),
        }));
      });
  };

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-bold mb-4 text-center text-charcoal">
        {t("register")}
      </h2>

      {errorMessages.general && (
        <p className="text-red-500 mb-4 text-center">{errorMessages.general}</p>
      )}

      {/* Show tenant name if ID is valid */}
      {isIdValid && tenantName && (
        <h3 className="text-lg font-semibold mb-4 text-center text-charcoal">
          {t("tenant_name")}: {tenantName}
        </h3>
      )}

      {/* Input for Tenant UUID */}
      {!isIdValid && (
        <div className="mb-4">
          <label
            htmlFor="tenantUUID"
            className="block text-charcoal font-semibold mb-1"
          >
            {t("enterTenantUUID")}:
          </label>
          <div className="flex items-center">
            <input
              type="text"
              id="tenantUUID"
              name="tenantUUID"
              value={manualTenantUUID}
              onChange={handleTenantUUIDChange}
              className="flex-grow px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-persianGreen"
            />
            <button
              type="button"
              onClick={() => setShowScannerModal(true)} // Show modal on click
              className="ml-2 bg-persianGreen text-white font-semibold py-2 px-4 rounded-md hover:bg-green-700 transition duration-200"
            >
              {t("showScanner")}
            </button>
          </div>
          {errorMessages.tenantUUID && (
            <p className="text-red-500">{errorMessages.tenantUUID}</p>
          )}
        </div>
      )}

      {/* Modal for QR Scanner */}
      {showScannerModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded shadow-lg max-w-md w-full">
            <button
              className="text-red-500 text-right font-semibold"
              onClick={() => setShowScannerModal(false)}
            >
              {t("close")}
            </button>
            <QrScanner onScan={handleScanUUID} />
          </div>
        </div>
      )}

      {/* Only show the form if the tenant UUID is valid */}
      {isIdValid && (
        <form onSubmit={handleSubmit} className="space-y-4 mt-4">
          <div>
            <label
              htmlFor="username"
              className="block text-charcoal font-semibold mb-1"
            >
              {t("username")}:
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-persianGreen"
            />
            {errorMessages.username && (
              <p className="text-red-500">{errorMessages.username}</p>
            )}
          </div>

          <div>
            <label
              htmlFor="employee_nr"
              className="block text-charcoal font-semibold mb-1"
            >
              {t("employee_nr")}:
            </label>
            <input
              type="number"
              id="employee_nr"
              name="employee_nr"
              value={formData.employee_nr}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-persianGreen"
            />
            {errorMessages.employee_nr && (
              <p className="text-red-500">{errorMessages.employee_nr}</p>
            )}
          </div>

          <div>
            <label
              htmlFor="password"
              className="block text-charcoal font-semibold mb-1"
            >
              {t("password")}:
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-persianGreen"
            />
            {errorMessages.password && (
              <p className="text-red-500">{errorMessages.password}</p>
            )}
          </div>

          <div>
            <label
              htmlFor="confirmPassword"
              className="block text-charcoal font-semibold mb-1"
            >
              {t("confirmPassword")}:
            </label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-persianGreen"
            />
            {errorMessages.confirmPassword && (
              <p className="text-red-500">{errorMessages.confirmPassword}</p>
            )}
          </div>

          {/* Task Selection Component */}
          <TaskSelection
            selectedTasks={selectedTasks}
            setSelectedTasks={setSelectedTasks}
          />
          {errorMessages.tasks && (
            <p className="text-red-500">{errorMessages.tasks}</p>
          )}

          <button
            type="submit"
            className="w-full bg-persianGreen text-white font-semibold py-2 rounded-md hover:bg-green-700 transition duration-200"
          >
            {t("register")}
          </button>
        </form>
      )}
    </div>
  );
};

export default UserRegister;
