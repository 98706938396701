// Filename: src/contexts/TenantProvider.js

import React, { createContext, useEffect, useState } from "react";
import config from "../config";

export const TenantContext = createContext();

const TenantProvider = ({ children }) => {
  const [tenant, setTenant] = useState(null);

  useEffect(() => {
    // For local testing, use a hardcoded subdomain
    const subdomain =
      process.env.NODE_ENV === "development"
        ? "foodclub"
        : window.location.host.split(".")[0];

    // Fetch tenant data based on the subdomain
    fetch(`${config.API_URL}/tenant/validate`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ subDomain: subdomain }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Tenant data fetched:", data); // Log the fetched data
        if (data.isValid) {
          setTenant(data); // Store tenant data
        }
      })
      .catch((error) => console.error("Error fetching tenant data:", error));
  }, []);

  return (
    <TenantContext.Provider value={tenant}>{children}</TenantContext.Provider>
  );
};

export default TenantProvider;
