// Filename: src/components/BottomNavbar.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ClockPlus from "../assets/icons/ClockPlus.png";
import {
  FaUserClock,
  FaClock,
  FaCalendarCheck,
  FaUserShield,
  FaCalendarAlt,
  FaUser,
  FaUserEdit,
  FaCalendarPlus,
  FaHome,
  FaSignInAlt,
  FaUserCheck,
} from "react-icons/fa";

const UserBottomNavbar = ({ handleLogout, isLoggedIn }) => {
  const { t } = useTranslation();
  const [activeButton, setActiveButton] = useState("/"); // Default to home
  const [activeSubmenu, setActiveSubmenu] = useState(null); // Track active submenu

  const logout = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("userRole");
    //    localStorage.removeItem("tenantUUID");

    handleLogout();
    setActiveButton(""); // Clear active button on logout
  };

  const handleMenuClick = (path) => {
    setActiveButton(path); // Set active button
    setActiveSubmenu(null); // Close any open submenu
  };

  const handleSubmenuClick = (submenu) => {
    if (activeSubmenu === submenu) {
      setActiveSubmenu(null); // Close if the same submenu is clicked
    } else {
      setActiveSubmenu(submenu); // Open the selected submenu
    }
  };

  const userRole = localStorage.getItem("userRole");
  //  const tenantUUID = localStorage.getItem("tenantUUID"); // Retrieve tenantUUID from localStorage

  return (
    <nav className="sm:hidden fixed bottom-0 left-0 right-0 bg-orange-600 shadow-inner z-50 pb-2 pt-0">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="flex justify-around items-center h-20">
          {/* Home Button */}
          <Link
            to="/" // Removed tenantUUID from URL
            className={`flex items-center justify-center text-white rounded-lg ${
              activeButton === "/" ? "bg-gray-700 p-2 mb-4" : "p-2 mb-4"
            }`}
            onClick={() => handleMenuClick("/")}
          >
            <FaHome size={26} className="mb-1" />
          </Link>

          {/* Worked Hours Button */}
          {isLoggedIn && (
            <div className="relative">
              <button
                onClick={() => {
                  handleMenuClick("/user/view-worked-hours");
                  handleSubmenuClick("workedHours");
                }}
                className={`flex items-center justify-center rounded-lg text-white ${
                  activeButton === "/user/view-worked-hours" ||
                  activeButton === "/user/submit-worked-hours"
                    ? "bg-gray-700 p-2 mb-4"
                    : "p-2 mb-4"
                }`}
              >
                <FaClock size="26" className="mb-1" />
              </button>
              {activeSubmenu === "workedHours" && (
                <div className="fixed left-0 bottom-[88px] w-full bg-white z-40">
                  <Link
                    to="/user/check-in" // Removed tenantUUID from URL
                    className="flex items-center font-semibold px-4 py-2 text-gray-800 rounded-lg hover:bg-gray-100"
                    onClick={() => {
                      handleMenuClick("/user/check-in");
                      handleSubmenuClick("workedHours");
                    }}
                  >
                    <FaUserCheck size="20" className="mr-2" />
                    {t("user_check_in")}
                  </Link>
                  <Link
                    to="/user/view-worked-hours" // Removed tenantUUID from URL
                    className="flex items-center font-semibold px-4 py-2 text-gray-800 rounded-lg hover:bg-gray-100"
                    onClick={() => {
                      handleMenuClick("/user/view-worked-hours");
                      handleSubmenuClick("workedHours");
                    }}
                  >
                    <FaUserClock size="20" className="mr-2" />
                    {t("view_worked_hours")}
                  </Link>
                  <Link
                    to="/user/submit-worked-hours" // Removed tenantUUID from URL
                    className="flex items-center px-4 font-semibold py-2 text-gray-800 hover:bg-gray-100"
                    onClick={() => {
                      handleMenuClick("/user/submit-worked-hours");
                      handleSubmenuClick("workedHours");
                    }}
                  >
                    <img
                      src={ClockPlus}
                      alt="My Custom Icon"
                      className="mr-2 w-5 h-5"
                    />
                    {t("submit_hours")}
                  </Link>
                </div>
              )}
            </div>
          )}

          {/* Availability Button */}
          {isLoggedIn && (
            <div className="relative">
              <button
                onClick={() => {
                  handleMenuClick("/user/view-schedule");
                  handleSubmenuClick("availability");
                }}
                className={`flex items-center justify-center text-white rounded-lg ${
                  activeButton === "/user/view-schedule" ||
                  activeButton === "/user/set-availability"
                    ? "bg-gray-700 p-2 mb-4"
                    : "p-2 mb-4"
                }`}
              >
                <FaCalendarAlt size={26} className="mb-1" />
              </button>
              {activeSubmenu === "availability" && (
                <div className="fixed left-0 bottom-[88px] w-full bg-white shadow-lg z-40">
                  <Link
                    to="/user/view/schedule" // Removed tenantUUID from URL
                    className="flex items-center font-semibold px-4 py-2 text-gray-800 hover:bg-gray-100"
                    onClick={() => {
                      handleMenuClick("/user/view-schedule");
                      handleSubmenuClick("availability");
                    }}
                  >
                    <FaCalendarCheck size="20" className="mr-2" />
                    {t("view_schedule")}
                  </Link>
                  <Link
                    to="/user/set-availability" // Removed tenantUUID from URL
                    className="flex items-center font-semibold px-4 py-2 text-gray-800 hover:bg-gray-100"
                    onClick={() => {
                      handleMenuClick("/user/set-availability");
                      handleSubmenuClick("availability");
                    }}
                  >
                    <FaCalendarPlus size="20" className="mr-2" />
                    {t("set_availability")}
                  </Link>
                </div>
              )}
            </div>
          )}

          {/* Profile Button */}
          {isLoggedIn ? (
            <div className="relative">
              <button
                onClick={() => {
                  handleMenuClick("/user/edit-profile");
                  handleSubmenuClick("profile");
                }}
                className={`flex items-center justify-center text-white rounded-lg ${
                  activeButton === "/user/edit-profile" ||
                  activeButton === "/admin/"
                    ? "bg-gray-700 p-2 mb-4"
                    : "p-2 mb-4"
                }`}
              >
                <FaUser size={26} className="mb-1" />
              </button>
              {activeSubmenu === "profile" && (
                <div className="fixed left-0 bottom-[88px] w-full bg-white shadow-lg z-40">
                  {(userRole === "admin" || userRole === "manager") && (
                    <Link
                      to="/admin/" // Removed tenantUUID from URL
                      className="flex font-semibold items-center rounded-lg px-4 py-2 text-gray-800 hover:bg-gray-100"
                      onClick={() => {
                        handleMenuClick("/admin/");
                        handleSubmenuClick("profile");
                      }}
                    >
                      <FaUserShield size="20" className="text-xl mr-2" />
                      {t("admin")}
                    </Link>
                  )}
                  <Link
                    to="/user/edit-profile" // Removed tenantUUID from URL
                    className="flex items-center font-semibold px-4 py-2 text-gray-800 hover:bg-gray-100"
                    onClick={() => {
                      handleMenuClick("/user/edit-profile");
                      handleSubmenuClick("profile");
                    }}
                  >
                    <FaUserEdit size="20" className="mr-2" />
                    {t("edit_profile")}
                  </Link>
                  <div
                    className="flex font-semibold items-center px-4 py-2 text-gray-800 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      logout();
                      handleMenuClick("/"); // Reset to home after logout
                    }}
                  >
                    <FaSignInAlt size="20" className="mr-2" />
                    {t("logout")}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <Link
              to="/user/login" // Removed tenantUUID from URL
              className={`flex items-center justify-center text-white rounded-lg ${
                activeButton === "/user/login"
                  ? "bg-gray-700 p-2 mb-4"
                  : "p-2 mb-4"
              }`}
              onClick={() => handleMenuClick("/user/login")}
            >
              <FaSignInAlt size={26} className="mb-4" />
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
};

export default UserBottomNavbar;
